<template>
  <div class="page-container">
    <div class="header">
      <page-header @navBack="navBack" :back="true" :pageTitle="pageTitle">
      </page-header>
    </div>

    <div class="page-body">

      <div class="content">
        <div class="section-top">
            <img v-if="params.w==0" :src="require('../../assets/icon-allright'+(appno==1?'':appno)+'.png')" alt="avatar">
            <img v-else :src="require('../../assets/icon-allright'+(appno==1?'':appno)+'.png')" alt="avatar">
            <h4>{{params.w>0?'答错啦！':'答对啦！'}}</h4>
            <span>共练习{{params.all}}道题，{{params.w>0?'答对'+params.r+'题,'+'答错'+params.w+'题':'全部答对了。'}}</span>
        </div>
        <div class="section-bottom">
          <van-button
            class="btn"
            type="primary"
            block
            @click="navBack"
            >{{params.w>0?'重做错题':'继续学习'}}</van-button
          >
        </div>
      </div>
      
    </div>

  </div>
</template>

<script>
import PageHeader from "../../components/page-header.vue";
export default {
  data() {
    return {
      pageTitle: "随堂练习",
      title: '答对啦!',
      desc: '',
      params: {},
    };
  },
  computed: {
    appno() {
      return this.$store.state.app.appno
    },
    courseid() {
      return this.$store.state.user.courseid
    },
  },
  components: {
    PageHeader,
  },
  mounted() {
    this.params = this.$route.query;
  },
  methods: {
    navBack() {
      if (this.params.w>0) {
        this.$router.push({ path: '/exam', query: { id: this.params.id, subject: this.params.subject } })
      } else {
        this.$router.push({ path: '/video', query: { courseid: this.courseid, subject: this.params.subject } })
      }
    }
  },
};
</script>

<style lang="less" scoped>

.header {
  position: absolute;
  width: 100%;
  height: 200px;
  padding-bottom: 14px;
  border-bottom-left-radius: 26px 10px;
  border-bottom-right-radius: 26px 10px;
}

.tips {
  padding: 4px 12px;
  font-size: 12px;
}

.page-container {
  min-height: 100vh;
  background: #f5f5f5;
}
.page-body {
  margin-top: 0;
  padding: 100px 15px 15px;
  .content {
    position: relative;
    top: -14px;
    background: #fff;
    border-radius: 14px;
    padding: 15px 15px 35px;

    .section-top {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;

        img {
          width: 90px;
          height: 90px;
          margin: 30px auto 20px;
        }
      

        h4 {
          font-size: 20px;
          color: #444444;
          margin: 0 0 40px 0;
        }
        span {
          color: #666;
          font-size: 15px;
          margin-bottom: 40px;
        }
      
    }
  }

}

// .van-popup {
//   background: transparent;
// }
.van-popup .van-popover__arrow {
  background: #fca142;
}
.van-popover__content {
  background: #fca142;
  color: #fff;
}
.van-circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.circle-wrap {
  position: relative;
  height: 200px;
  .circle {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 160px;
    height: 160px;
    margin: -80px 0 0 -80px;
    background-image: url(~@/assets/icon-circle-sm.png);
    background-size: cover;
    .circle-inner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      .title {
        margin: 0 0 4px 0;
        color: #ff8506;
        font-size: 20px;
      }
      span {
        color: #999;
        font-size: 11px;
      }
    }
  }
}

.popup-content {
  width: 290px;
  // border-radius: 12px;
  overflow: hidden;
  background: #fff;
  &>.title {
    background: linear-gradient(90deg, #FCA142, #FF8506);
    padding: 15px;
    font-size: 18px;
    color: #fff;
    text-align: center;
  }
  .content {
    margin: 20px 0 10px;
    padding: 0 10px;
    font-size: 12px;
    .list {
      display: flex;
      align-items: center;
      margin-top: 10px;
      span {
        display: inline-block;
        width: 50%;
      }
    }
  }
  .btn-wrap {
    display: flex;
    padding: 15px;
    .btn {
      width: 40%;
      font-weight: 500;
    }
  }
}
</style>